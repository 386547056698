import './ParentDoorsCategoryList.css'
import {Link, useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {
  getCatalogById,
  getTailProductsDoorsParentLevel
} from "../../../utils/doors/apiDoors";
import AccordionCategory from "../../Accordion/Doors/AccordionCategory/AccordionCategory";
import Preloader from "../../Preloader/Preloader";
import {
  CATEGORIES,
  CATEGORY_ID, COLLECTION_ID,
  COLLECTIONS,
  DOORS,
  PAGE_ID,
  PARENT_CATEGORY_ID,
  VIEW_COLLECTIONS
} from "../../../constants/constants";
import PaginationComp from "../../Pagination/Pagination";
import parserPath from "../../../utils/parserPath";
import calcRangeList from "../../../utils/calcRangeList";
import PreviewDoor from "../../Preview/PreviewDoor/PreviewDoor";
import CategoryDoors from "../../CategoryItem/CategoryDoors/CategoryDoors";
import {getValueFromPath} from "../../../utils/doors/getCategoryId";

export default function ParentDoorsCategoryList({isLoading, setIsLoading, addToBasket}) {
  const [parentCategory, setParentCategory] = useState({})
  const [collectionsList, setCollections] = useState([])
  const location = useLocation()
  const [collectionListLength, setCollectionListLength] = useState(0)
  const navigate = useNavigate();

  function goBack() {
    navigate(-1)
  }

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);
    const categoryId = getValueFromPath(location.pathname, PARENT_CATEGORY_ID)

    getCatalogById(categoryId)
      .then(res => {
        setCollections(res.category)
      })
      .catch(console.error)
      .finally(() => setTimeout(() => setIsLoading(false), 500))

  }, [location.pathname]);

  return (
    <div className="hero">
      <div className="button__wrapper-hero">
        <button className="doors-category__go-back" onClick={goBack}>назад</button>
      </div>

      {
        collectionsList?.map(
          item => {
            return (
              <CategoryDoors
                key={item.id}
                name={item.name}
                category={item.category}
                id={item.id}
                preview={item.preview}
                categoryType={COLLECTION_ID}
                pageId="&pageId=1"
              />)
          })
      }
    </div>
  )
}
