import './CategoryDoors.css';
import { Link } from "react-router-dom";
import { useState } from "react";
import {
  changeCategoryPreview
} from "../../../utils/doors/apiDoors";
export default function CategoryDoors({ name, id, preview, categoryType, pageId }) {
  const [isHidden, setIsHidden] = useState(false); // Скрытие компонента в случае ошибки загрузки изображения
  const [previewImage, setPreviewImage] = useState(preview); // Скрытие компонента в случае ошибки загрузки изображения

  // Обработка ошибки загрузки изображения
  const handleImageError = () => {
    console.log(`Image for category ${id} failed to load.`);
    // setIsHidden(true); // Скрываем компонент
    changeCategoryPreview(id)
      .then(res => {
        setPreviewImage(res.newPreview)
      })
  };

  // Если компонент скрыт, не рендерим его
  if (isHidden) {
    return null;
  }

  return (
    <Link to={`${categoryType}=${id}${pageId}`} className="parent-category__link">
      <img
        src={previewImage}
        alt="превью"
        className="parent-category__preview"
        onError={handleImageError} // Событие ошибки загрузки
      />
      <p className="parent-category__title">{name}</p>
    </Link>
  );
}
