import './PreviewDoor.css';
import { Link } from "react-router-dom";
import { Cart } from "react-bootstrap-icons";
import { useState } from "react";
import {deleteProductById} from "../../../utils/doors/apiDoors";

export default function PreviewDoor({ id, cardData, addToBasket }) {
  const { price, image, title, model, quantity } = cardData;
  const [isHidden, setIsHidden] = useState(false); // Скрытие компонента в случае ошибки загрузки изображения

  // Заглушка для удаления товара
  const deleteProduct = async (productId) => {
    await deleteProductById(productId)
      .then((response) => {
        console.log(response); // Успешное удаление
      })
      .catch((error) => {
        console.error("Error deleting product:", error); // Обработка ошибки
      });
  };

  // Обработка ошибки загрузки изображения
  const handleImageError = () => {
    console.warn(`Image for product ${id} failed to load.`);
    setIsHidden(true); // Скрываем компонент
    deleteProduct(id); // Удаляем товар
  };

  // Если компонент скрыт, не рендерим его
  if (isHidden) {
    return null;
  }

  return (
    <Link to={`/doors/product/productId=${id}`} className="preview-wrapper">
      <div className="preview__door">
        <img
          src={image}
          alt="превью"
          className="preview__image"
          onError={handleImageError} // Событие ошибки загрузки
        />
        <h3 className="preview__name">{title}</h3>
        <div className="preview__to">
          <div className="preview__price">
            <span className="special__price">{price}</span>
          </div>
          <button
            type="button"
            className="btn btn-warning basket-btn"
            onClick={(event) => addToBasket(event, title, model, price, image, 1, quantity)}
          >
            <Cart
              size={25}
              color="white"
              className="menu__item__image"
              style={{ verticalAlign: "middle", margin: 0 }}
            />
          </button>
        </div>
      </div>
    </Link>
  );
}
